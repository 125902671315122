var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "match-game-layout" },
    [
      _vm._t("default", function () {
        return [
          _c(
            "div",
            { staticClass: "match-game-layout__cards" },
            [
              _c(
                "transition-group",
                {
                  staticClass: "match-game-layout__top",
                  attrs: { tag: "div", name: "social-player-transition" },
                },
                [_vm._t("top-items")],
                2
              ),
              _c(
                "draggable",
                _vm._b(
                  {
                    staticClass: "match-game-layout__draggable-row",
                    attrs: {
                      "force-fallback": "",
                      direction: "horizontal",
                      tag: "div",
                      disabled: _vm.isDraggable,
                      move: _vm.checkMove,
                    },
                    on: {
                      end: function ($event) {
                        return _vm.$emit("handleDragEnd")
                      },
                    },
                    model: {
                      value: _vm.facts,
                      callback: function ($$v) {
                        _vm.facts = $$v
                      },
                      expression: "facts",
                    },
                  },
                  "draggable",
                  {
                    ghostClass: "match-game-layout__ghost",
                    group: "facts",
                    animation: 150,
                  },
                  false
                ),
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "match-game-layout__middle",
                      attrs: { tag: "div", name: "fact-transition" },
                    },
                    [_vm._t("bottom-items")],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "match-game-layout__bottom" },
            [_vm._t("submit")],
            2
          ),
          _vm._t("pointer"),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }