import useStore from "@/use/useStore"
import { computed } from "@vue/composition-api"

export default function useMatchGame() {
  const { store } = useStore()
  const isHost = computed(() => store.getters["auth/isHost"])
  const isScribe = computed(() => store.getters.isScribe)
  const user = computed(() => store.getters["auth/user"])
  const answers = computed(() => store.getters.missionPlaysArray)

  const isDragOn = computed(() => isHost.value || isScribe.value)
  const isCompleted = computed(() =>
    answers.value.some(
      ({ score, completed, teamID }) =>
        (parseInt(score) > 0 || completed) &&
        (teamID === user.value.teamID ||
          (isHost.value && teamID === store.getters["group/globalTeamID"]))
    )
  )
  const isDisabled = computed(() => !isDragOn.value || isCompleted.value)
  const showSubmit = computed(
    () => (isScribe.value || isHost.value) && !isCompleted.value
  )

  const submitText = computed(() =>
    showSubmit.value
      ? "Submit"
      : isCompleted.value
      ? "Submitted"
      : "waiting on the scribe..."
  )

  return {
    isCompleted,
    showSubmit,
    isDisabled,
    submitText
  }
}
