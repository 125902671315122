









import { mapGetters } from "vuex"
import Vue from "vue"
import { TimelineLite, TweenMax, Power2 } from "gsap/TweenMax"

export default Vue.extend({
  name: "DragPointer",

  props: {
    rootClassname: {
      type: String,
      required: true
    },
    elementClassname: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      root: null,
      cursor: null,
      showCursor: false,
      cursorTarget: null,
      cursorOffest: {
        x: 0,
        y: 0
      }
    }
  },
  computed: {
    ...mapGetters(["isScribe"]),
    ...mapGetters("auth", ["isHost"]),
    isDragOn() {
      return this.isScribe || this.isHost
    }
  },
  mounted() {
    this.animateCursor()
  },
  beforeDestroy() {
    try {
      TweenMax.ticker.removeEventListener("tick", this.onTick)
    } catch (e) {
      console.log(e.message)
    }
  },
  methods: {
    onTick() {
      if (this.cursorTarget && this.root) {
        const rootClientRect = this.root.getBoundingClientRect()
        const cursorTargetClientRect = this.cursorTarget.getBoundingClientRect()

        const x =
          cursorTargetClientRect.left +
          this.cursorOffest.x -
          rootClientRect.left
        const y =
          cursorTargetClientRect.top + this.cursorOffest.y - rootClientRect.top

        this.cursor.style.left = x + "px"
        this.cursor.style.top = y + "px"
      }
    },
    animateCursor() {
      if (this.isDragOn) {
        setTimeout(() => {
          // cursor animation
          this.cursor = this.$refs.cursor
          this.root = document.querySelector(this.rootClassname)
          this.cursorTarget = this.root.querySelector(this.elementClassname)
          if (!this.cursorTarget) return null

          TweenMax.ticker.addEventListener("tick", this.onTick)

          const OFFSET_X = 300
          const OFFSET_Y = 300

          const tl = new TimelineLite({
            paused: true,
            onComplete: () => {
              this.showCursor = false
              TweenMax.ticker.removeEventListener("tick", this.onTick)
            }
          })

          tl.from(
            this.cursorOffest,
            0.8,
            { x: OFFSET_X, y: OFFSET_Y, ease: Power2.easeOut },
            "+=0.5"
          )
            .to(this.cursor, 0.25, { scale: 0.95 }, "+=0.5")
            .set(this.cursorTarget, { className: "+=selected" }, "-=0.15")
            .to(this.cursorOffest, 0.5, { x: 15 }, "+=0.0")
            .to(this.cursorTarget, 0.5, { x: 15 }, "-=0.5")
            .to(this.cursor, 0.25, { scale: 1 }, "+=0.25")
            .set(this.cursorTarget, { className: "-=selected" }, "-=0.15")
            .to(this.cursorTarget, 0.5, { x: 0 }, "-=0.15")
            .to(this.cursorOffest, 0.5, { x: 30 }, "-=0.5")
            .to(
              this.cursorOffest,
              0.75,
              { y: OFFSET_Y, ease: Power2.easeIn },
              "+=0.1"
            )

          this.showCursor = true
          tl.play()
        }, 1000)
      }
    }
  }
})
