














































import Vue from "vue"
import draggable from "vuedraggable"

export default Vue.extend({
  name: "MatchGameLayout",
  model: {
    prop: "bottomItems",
    event: "input"
  },
  components: {
    draggable
  },
  props: {
    bottomItems: {
      type: Array,
      default: undefined
    },
    isDraggable: {
      type: Boolean,
      default: undefined
    },
    checkMove: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    facts: {
      get() {
        return this.bottomItems
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  }
})
