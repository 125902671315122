var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.showCursor,
        expression: "showCursor",
      },
    ],
    ref: "cursor",
    staticClass: "drag-pointer",
    attrs: { src: require("@/assets/hand-cursor-image.svg") },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }